import { useContext, useState, useEffect } from "react";
import {
    TagGroup,
    Tag,
    makeStyles,
    shorthands,
    tokens,
    Field,
    Input,
    Spinner,
    Button,
    Switch,
} from "@fluentui/react-components";
import { useMediaQuery } from 'react-responsive';

import { useNavigate } from "react-router-dom";
import { ArrowLeft20Regular, Attach20Regular } from "@fluentui/react-icons";
import Dropzone from 'react-dropzone';

import { useRest } from "../../hooks/useRest";

import { TicketsDataContext } from "../contexts/TicketsDataContext";
import { LoggingContext } from "../contexts/LoggingContext";

import StyledCard from "../../components/StyledCard";
import Editor from "../../components/Editor";
import Scheduler from "../../components/Scheduler";
import { dateTimeLong } from "../../utils";
import { fonts } from "../../styles";
import TicketAttachments from "../../components/TicketAttachments";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    backArrow: {
        marginRight: tokens.spacingHorizontalM,
        cursor: 'pointer'
    },
    subject: {
        width: '100%',
    },
    mobileSubject: {
        width: '100%',
    },
    detailsMultiLine: {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),

        maxHeight: '500px',
        overflowY: 'auto',
    },
    schedulerInstructions: {
        marginLeft: tokens.spacingHorizontalS,
        marginTop: tokens.spacingVerticalS
    },
    schedulerRow: {
        marginTop: tokens.spacingVerticalL,
        marginLeft: tokens.spacingHorizontalS,
        display: 'flex',
        flexDirection: 'row',
    },
    selectedSlot: {
        marginTop: tokens.spacingVerticalL,

        ...fonts.label
    },
    tagGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // FileTag Styles
    tags: {
        marginTop: tokens.spacingVerticalS,
    },
    dropzone: {
        cursor: 'pointer'
    },
    validationError: {
        color: tokens.colorStatusDangerForeground1
    }
})

const CreateTicket = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const navigate = useNavigate();
    const { createTicket, contact, tickets, setTickets } = useContext(TicketsDataContext);
    const { trackEvent, trackException } = useContext(LoggingContext);

    const [subject, setSubject] = useState('');
    const [details, setDetails] = useState('');
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [isSaving, setIsSaving] = useState(false);

    // Bookings state
    const [scheduleCall, setScheduleCall] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState<any>(null);

    // attachments state
    const [attachments, setAttachments] = useState<any[]>([]);

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };

    const handleBackClick = () => {
        navigate('/');
    }

    const handleScheduleCallClick = (ev: any) => {
        setSelectedSlot(null);
        setScheduleCall(!scheduleCall);
    };

    const handleCreateTicket = () => {
        setValidationErrors([]);

        const attachmentValidationError = validateAttachments();
        if (attachmentValidationError) {
            setValidationErrors([attachmentValidationError]);
            return;
        }

        const validationErrorsLocal = validateModel();

        if (validationErrorsLocal.length > 0) {
            setValidationErrors(validationErrorsLocal);
            return;
        }

        let form = new FormData();
        form.append('Subject', subject);
        form.append('Description', details);

        if (scheduleCall) {
            form.append('Booking.StartTime', selectedSlot.startTime);
            form.append('Booking.EndTime', selectedSlot.endTime);

            selectedSlot.staffIds.forEach((staffId: any) => {
                form.append('Booking.StaffIds', staffId);
            });
        }

        if (attachments.length > 0) {
            attachments.forEach((attachment) => {
                form.append('Attachments', attachment)
            });
        }

        setIsSaving(true);
        createTicket(form).then((createdTicket: any) => {
            createdTicket.contactName = contact.fullName;
            const newTicketState = [...tickets, createdTicket]

            setTickets(newTicketState);
            setIsSaving(false);

            if (scheduleCall) {
                trackEvent('ticketCreated-withAppointment', { ticketId: createdTicket.id });
            }
            else {
                trackEvent('ticketCreated', { ticketId: createdTicket.id });
            }

            handleCancel();
        }).catch(err => {
            debugger;
            setIsSaving(false);
            setValidationErrors(['There was an issue saving your ticket, please try again later.'] as any[]);
            trackException(err);
        });
    };

    const handleCancel = () => {
        setSubject('');
        setDetails('');
        setValidationErrors([]);
        navigate('/');
    }

    const handleAddAttachments = (addedAttachments: any[]) => {
        setValidationErrors([]);

        setAttachments(addedAttachments);
    }

    const validateAttachments = () => {
        if (attachments && attachments.length > 0) {
            let totalSize = 0;
            attachments.forEach((attachment) => {
                totalSize += attachment.size;
            });

            // 290MB in Bytes
            if (totalSize > 304_087_040) {
                return 'Ticket total size should be less than 290MB. Please remove some attachments and try again.';
            }
        }

    }

    const validateModel = () => {
        let validationErrorsLocal: any[] = [];
        if (subject === '') {
            validationErrorsLocal.push('Subject is required');
        }

        if (details === '') {
            validationErrorsLocal.push('Details are required');
        }

        if (scheduleCall) {

            if (!selectedSlot) {
                validationErrorsLocal.push('Please select an available appointment.');
            }
        }

        return validationErrorsLocal;
    }

    const classes = useStyles();
    const subjectClass = isDesktopOrLaptop ? classes.subject : classes.mobileSubject;

    return (
        <>
            <StyledCard
                title={
                    <div className={classes.header}>
                        <div className={classes.backArrow}>
                            <ArrowLeft20Regular onClick={handleBackClick} />
                        </div>
                        <div>Create Ticket</div>
                    </div>}
                footer={
                    <>
                        <Button
                            disabled={isSaving}
                            onClick={handleCancel}
                            appearance="secondary">Cancel</Button>
                        <Button
                            disabled={isSaving || validateModel().length > 0}
                            onClick={handleCreateTicket}
                            appearance="primary">
                            {
                                isSaving ?
                                    <Spinner
                                        size='tiny'
                                        label='Saving...' />
                                    : 'Create Ticket'
                            }
                        </Button>
                    </>
                }
            >
                <Field label='Subject'>
                    <Input className={subjectClass} value={subject} onChange={handleSubjectChange} maxLength={300} autoFocus />
                </Field>
                <Field label='Ticket Details'>
                    <Editor
                        editorText={details}
                        onEditorChange={(ticketText: string) => setDetails(ticketText)} />
                </Field>
                <TicketAttachments files={attachments} onFilesChange={(attachments: any) => handleAddAttachments(attachments)} />
                <div>
                    <Switch
                        label="Schedule a call"
                        checked={scheduleCall}
                        onChange={handleScheduleCallClick} />
                    {scheduleCall ? <>
                        <div className={classes.schedulerInstructions}>Please select an appointment date and time.</div>
                        <div className={classes.schedulerRow}>
                            <Scheduler selectedSlot={selectedSlot} onSlotChange={(slot: any) => { setSelectedSlot(slot) }} />
                        </div>
                    </> : <></>}
                </div>

                {validationErrors.length > 0 ?
                    validationErrors.map(x => {
                        return <div className={classes.validationError}>*{x}</div>
                    }) : <div></div>
                }
            </StyledCard>
        </>
    )
}

export default CreateTicket;