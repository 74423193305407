import { useMsal, useAccount } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { LoggingContext } from "../app/contexts/LoggingContext";
import { TeamsFxContext } from "../app/Context";
import { CacheLookupPolicy, InteractionRequiredAuthError, AuthenticationResult } from "@azure/msal-browser";
import { scopes } from "../app/constants";

export const useIdentity = () => {
    const logFilePrefix = 'useIdentity';

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { teamsUserCredential, host } = useContext(TeamsFxContext);
    const [tenantId, setTenantId] = useState<string>("");
    const [roles, setRoles] = useState<any>([]);
    const [signInRequired, setSignInRequired] = useState<boolean>(false);
    const [authError, setAuthError] = useState<boolean>(false);

    const { trackEvent, trackTraceVerbose, trackException, trackTraceCritical,
        trackTraceWarning, trackTraceError, setAuthenticatedUserContext } = useContext(LoggingContext);

    // MSAL Auth, retrieve roles, tenantId from token, authenticatedUserContext.
    useEffect(() => {

        (async () => {
            const logName = `${logFilePrefix}-useEffect-msal`;

            if (!account) {
                trackTraceWarning(`${logName}-guardClause: deps[account]`)
                return;
            }

            trackTraceVerbose(`${logName}`)
            try {
                const accessToken = await getAccessToken();

                if (!accessToken) {
                    setAuthError(true);
                    return Promise.reject('Unable to retrieve access token.');
                }

                // extract roles from token
                trackTraceVerbose(`${logName}-parse-token`);
                var tokenParts = accessToken.split('.');
                var tokenPayload = JSON.parse(atob(tokenParts[1]));

                setRoles(tokenPayload.roles);
                trackTraceVerbose(`${logName}-roles-set: ${JSON.stringify(tokenPayload.roles)}`);

                // set tenantId
                const tid = account.tenantId;
                setTenantId(tid);

                // setAuthenticatedUserContext 
                setAuthenticatedUserContext(tokenPayload.oid, tid)
                trackTraceVerbose(`${logName}-setAuthenticatedUserContext-userId: ${tokenPayload.oid}`)
                trackTraceVerbose(`${logName}-setAuthenticatedUserContext-tenantId: ${tid}`)

            }
            catch (error: any) {
                trackException(error);
            }
        })();

    }, [account]);

    // TeamsFx Auth, retrieve roles, tenantId from token, set authenticatedUserContext.
    useEffect(() => {
        (async () => {
            const logName = `${logFilePrefix}-useEffect-teamsfx`;
            // wait until teamsUserCredential is available
            if (!teamsUserCredential || host === 'standalone' || host === 'unknown') {
                trackTraceWarning(`${logName}-guardClause: deps[teamsUserCredential, host]`)
                return;
            }

            trackTraceVerbose(`${logName}-host: ${host}`)
            trackTraceVerbose(`${logName}-retrieveAccessToken`);
            let accessToken = await getAccessToken();

            if (!accessToken) {
                setAuthError(true);
                return Promise.reject('Unable to retrieve access token.');
            }

            // retrieve roles from token
            trackTraceVerbose(`${logName}-parse-roles`);
            var tokenParts = accessToken.split('.');
            var tokenPayload = JSON.parse(atob(tokenParts[1]));

            setRoles(tokenPayload.roles);
            trackTraceVerbose(`${logName}-roles-set: ${JSON.stringify(tokenPayload.roles)}`);

            // set tenantId, authenticatedUserContext
            const { objectId, tenantId: tid } = await teamsUserCredential?.getUserInfo()
            trackTraceVerbose(`${logName}-userInfo-success`);
            setTenantId(tid);
            trackTraceVerbose(`${logName}-tenantId-set: ${tid}`);

            setAuthenticatedUserContext(objectId, tid);
            trackTraceVerbose(`${logName}-setAuthenticatedUserContext-userId: ${objectId}`)
            trackTraceVerbose(`${logName}-setAuthenticatedUserContext-tenantId: ${tid}`)
        })();

    }, [teamsUserCredential, host]);

    async function login(): Promise<void> {
        const logName = `${logFilePrefix}-login`;

        if (account) {
            trackEvent('login-account', account);
            var silentRequest = {
                scopes,
                account,
                forceRefresh: false,
                cacheLookupPolicy: CacheLookupPolicy.RefreshToken // will default to CacheLookupPolicy.Default if omitted
            };

            var request = {
                scopes,
                loginHint: account.username // For v1 endpoints, use upn from idToken claims
            };

            const tokenResponse = await instance.acquireTokenSilent(silentRequest).catch(async (error) => {
                trackTraceVerbose(`${logName}-acquireTokenSilent-error`);
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return await instance.acquireTokenPopup(request).catch(error => {
                        trackTraceCritical(`${logName}-acquireTokenPopup-error`);
                        trackException(error);
                        setAuthError(true);
                        throw error;
                    });
                }
            });

            // login successful
            tokenResponse && setSignInRequired(false);
        }
        else {
            try {
                trackTraceVerbose(`${logName}-teamsfx-attempt-login`);
                await teamsUserCredential?.login(scopes);
                trackTraceVerbose(`${logName}-teamsfx-attempt-login-success`);

                const token = await teamsUserCredential?.getToken(scopes)
                if (!token) {
                    trackTraceError(`${logName}-teamsfx-login-getToken-error`);
                    setAuthError(true);
                }

                // set tenantId, authenticatedUserContext
                if (teamsUserCredential) {
                    const { objectId, tenantId: tid } = await teamsUserCredential?.getUserInfo()
                    trackTraceVerbose(`${logName}-userInfo-success`);
                    setTenantId(tid);
                    trackTraceVerbose(`${logName}-tenantId-set: ${tid}`);
                }

                setSignInRequired(false);
            }
            catch (error: any) {
                setAuthError(true);
                trackTraceCritical(`${logName}-teamsfx-login-failed`);
                trackTraceCritical(`${logName}-teamsfx-login-failed-message: ${error.message}`);
                trackException(error);
            }
        }
    }

    async function getAccessToken(): Promise<any> {

        const logName = `${logFilePrefix}-getAccessToken`;
        if (account) {
            trackTraceVerbose(`${logName}-msal`);

            try {
                const authenticationResult = await instance.acquireTokenSilent({
                    scopes,
                    account: account
                });

                return authenticationResult.accessToken;
            }
            catch (err: any) {
                trackTraceError(`${logName}-msal-account-error: ${err.message}`);
                trackException(err);
            }
        }
        else {
            trackTraceVerbose(`${logName}-teamsfx`);

            let token = await teamsGetToken()
            if (!token) {
                trackTraceVerbose(`${logName}-teams-getToken-Unsuccessful-attempt-login`);
                try {
                    await teamsUserCredential?.login(scopes);
                }
                catch (error: any) {
                    setAuthError(true);
                    trackTraceError(`${logName}-teams-login-error: ${error.message}`);
                    trackException(error);
                }

                trackTraceVerbose(`${logName}-teams-login-complete`);

                token = await teamsGetToken();

                if (!token) {
                    trackTraceError(`${logName}-teamsfx-auth-error`);
                    setAuthError(true);
                }
            }

            return token;
        }
    }

    const teamsGetToken = async () => {
        const logName = `${logFilePrefix}-teamsGetToken`;

        try {
            const authenticationResult = await teamsUserCredential?.getToken(scopes);
            trackTraceVerbose(`${logName}-success`);

            return authenticationResult?.token;
        }
        catch (error: any) {
            trackTraceError(`${logName}-error: ${error.message}`);
            trackException(error);
        }

        return null;
    }

    return {
        tenantId,
        roles,
        login,
        getAccessToken,
        signInRequired,
        authError
    };
}