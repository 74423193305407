import { useState, useContext } from 'react'
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';
import { Account } from '../types/accountTypes';
import {
    Dropdown,
    Option } from '@fluentui/react-components'

export default function AccountDropdown({accounts, selectedAccount}:{accounts:Account[], selectedAccount:Account }) {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([selectedAccount.tenantId]);
    const [value, setValue] = useState<string>(selectedAccount.tenantName);
    const { setAccounts } = useContext(AccountsDataContext);

    const onOptionSelect = (ev: any, data: any) => {
        setSelectedOptions(data.selectedOptions);
        setValue(data.optionText)

        //Update the selected account
        const mappedAccounts = accounts.map((x: any) => x.tenantId === data.optionValue);

        const accountsMapped = accounts.map((x:any) => {
            return {
                ...x,
                selected: x.tenantId === data.optionValue
            }
        });
        setAccounts(accountsMapped);
    };



    return (
    <>
        <Dropdown
            value={value}
            onOptionSelect={onOptionSelect}
            selectedOptions={selectedOptions}
            >
            {
                accounts.length > 0 && accounts.map(account => {
                    return (
                        <Option key={account.tenantId} value={account.tenantId} text={account.tenantName}>{account.tenantName}</Option>
                    )
            })
            }
        </Dropdown>
    </>
    );
}