import {
    useContext
} from 'react'

import { TicketsDataContext } from '../../app/contexts/TicketsDataContext';
import StyledCard from '../StyledCard';

import {
    makeStyles,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    shorthands
} from '@fluentui/react-components';

import { useMediaQuery } from 'react-responsive';
import { fonts } from '../../styles';
import { dateTime } from '../../utils';
import { STATUS_TYPES } from '../../app/constants';

import NoTickets from './NoTickets';
import { IdentityDataContext } from '../../app/contexts/IdentityDataContext';
import { useNavigate } from 'react-router-dom';

const useTableStyles = makeStyles({
    title: {

        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tableHeader: {
        '& tr th div': {
            ...fonts.tableHeader
        }
    },
    tableBody: {
        ...fonts.body
    },
    clickable: {
        cursor: 'pointer'
    }
});

export default function ClosedTickets({ className }) {

    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const { tickets, ticketsLoading, ticketError } = useContext(TicketsDataContext);
    const { roles } = useContext(IdentityDataContext);

    let closedCases = [];
    if (!ticketError) {
        closedCases = tickets.filter(x => {
            return x.statusType === STATUS_TYPES.CLOSED;
        }).sort((a, b) => new Date(b.closedAt) - new Date(a.closedAt))
    }

    const tableClasses = useTableStyles();

    // TODO: This is a hack to determine if the user has the ServiceManager.Read role
    // Create const for different application roles
    // look into a better way to do this, conditional statments may get to be too tedious
    const displayTicketCreator = roles && (roles[0] === 'ServiceManager.Read' || roles[0] === 'Administrator.Read') ? true : false;

    return (
        <StyledCard
            className={className}
            title='Closed Tickets in the Last 30 Days'
            isLoading={ticketsLoading}
            error={ticketError}
        >
            {closedCases.length > 0 ?
                <Table className={tableClasses.tableStyles}
                    size='small'>
                    <TableHeader className={tableClasses.tableHeader}>
                        <TableRow>
                            {/* TODO This needs to be generated based on API Request */}
                            <TableHeaderCell>Title</TableHeaderCell>
                            {displayTicketCreator && isDesktopOrLaptop && <TableHeaderCell>Ticket Creator</TableHeaderCell>}
                            {isDesktopOrLaptop &&
                                <>
                                    <TableHeaderCell>Created</TableHeaderCell>
                                    <TableHeaderCell className={tableClasses.tableHeader}>Closed</TableHeaderCell>
                                </>
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody className={tableClasses.tableBody}>
                        {closedCases.map((x) => {
                            return (
                                <TableRow
                                    className={tableClasses.clickable}
                                    onClick={() => navigate(`/ticketDetails/${x.id}`)}
                                    key={x.id}>
                                    <TableCell
                                        className={tableClasses.title}
                                    >
                                        {x.subject}
                                    </TableCell>
                                    {displayTicketCreator && isDesktopOrLaptop && <TableCell>{x.contactName}</TableCell>}
                                    {isDesktopOrLaptop &&
                                        <>
                                            <TableCell>{dateTime(x.createdAt)}</TableCell>
                                            <TableCell>{dateTime(x.closedAt)}</TableCell>
                                        </>
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                : <NoTickets message='There are no closed tickets assigned to you' />
            }
        </StyledCard>
    )
};