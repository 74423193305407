
import { useState, createContext, useEffect } from 'react';
import useTicketsData from '../../hooks/useTicketsData';

const ticketDetailsDefault = {
    ticketActivities: [] as any[],
    statusType: '',
    status: '',
    description: '',
    attachments: [] as any[]
}

export const TicketsDataContext = createContext({
    tickets: [] as any[],
    ticketsLoading: false,
    contact: { id: '', fullName: '', phone: '', email: '', jobTitle: '', assignedLicenses: [] },
    contactLoading: false,
    createTicket: (ticket: FormData) => Promise.resolve({}),
    createComment: (ticketId: any, comment: FormData) => Promise.resolve({}),
    uploadAttachments: (ticketId: string, attachments: FormData) => Promise.resolve({}),
    downloadAttachment: (ticketId: string, attachmentId: string) => Promise.resolve({}),
    deleteAttachment: (ticketId: string, attachmentId: string) => Promise.resolve({}),
    getTicketDetails: (id: string) => Promise.resolve(ticketDetailsDefault),
    setTickets: (tickets: any) => { },
    ticketError: undefined as any
});

export const TicketsDataProvider = ({ children }: { children: any }) => {

    const { 
        data,
        ticketsLoading,
        contactData,
        contactLoading,
        createTicket,
        createComment,
        uploadAttachments,
        downloadAttachment,
        deleteAttachment,
        getTicketDetails,
        setTickets,
        ticketError
    } = useTicketsData();

    return (
        <TicketsDataContext.Provider
            value={{
                tickets: data,
                ticketsLoading,
                contact: contactData,
                contactLoading,
                createTicket,
                createComment,
                uploadAttachments,
                downloadAttachment,
                deleteAttachment,
                getTicketDetails,
                setTickets,
                ticketError
            }}>
            {children}
        </TicketsDataContext.Provider>
    );

};


