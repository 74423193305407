import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoggingProvider } from "./app/contexts/LoggingContext";
import Root from "./app/Root"
import ErrorPage from "./error-page";
import Assistance from "./app/assistance/page";
import Notifications from "./app/notifications/page";
import Reporting from "./app/reports/page";
import TicketDetails from "./app/ticketDetails/page";
import CreateTicket from "./app/ticketCreate/page";
import Login from "./app/login/page";
import Welcome from "./app/welcome/page";
import "./index.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Assistance />
            },
            {
                path: "/notifications",
                element: <Notifications />
            },
            {
                path: "/reports",
                element: <Reporting />
            },
            {
                path: "/ticketDetails/:ticketId",
                element: <TicketDetails />
            },
            {
                path: "/createTicket/",
                element: <CreateTicket />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/welcome",
                element: <Welcome />
            }
        ]
    },
]);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <LoggingProvider>
        <RouterProvider router={router} />
    </LoggingProvider>
);

