export const STATUSES = {
    IN_PROGRESS: 'In Progress', 
    NEW: 'New',
    ASSIGNED: 'Assigned',
    ON_HOLD: 'On Hold',
    RESOLVED: 'Resolved',
    CLOSED: 'Closed'
}

export const STATUS_TYPES = {
    OPEN: 'Open',
    CLOSED: 'Closed'
}

// Not really a constant, but if we have more configuration items we'll move them to a different file
export const scopes = [
        "openid",
        "profile",
        process.env.REACT_APP_API_SCOPE as string,
    ];

export const CLIENTS = {
    DESKTOP: 'desktop',
    WEB: 'web',
    IOS: 'ios',
    IPAD: 'ipados',
    ANDROID: 'android',
}

export const ERROR_CODES = {
    ACCOUNT_NOT_FOUND: "account_not_found",
    UNSUPPORTED_CLIENT_TYPE: "unsupported_client_type",
    SERVICE_UNAVAILABLE: "service_unavailable",
}