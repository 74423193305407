import { makeStyles, tokens } from "@fluentui/react-components";

const fontBase = {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: tokens.fontWeightRegular,
}

export const fonts = {
    title: {
        ...fontBase,
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase200,
    },
    body: {
        ...fontBase,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase200,
    },
    label: {
        ...fontBase,
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase200,
    },
    cardTitle: {
        ...fontBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase200,
    },
    cardSubtitle: {
        ...fontBase,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
    tableHeader: {
        ...fontBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
    }


}