import { useContext } from "react";
import { FluentProvider, makeStyles, tokens, teamsLightTheme, teamsDarkTheme, shorthands } from "@fluentui/react-components";
import { Outlet } from "react-router-dom";

import AuthenticationProvider from "../providers/AuthenticationProvider";

import Header from "../components/header/Header";
import Welcome from "./welcome/page";

import { NotificationsDataProvider } from "./contexts/NotificationsDataContext";
import { IdentityDataProvider } from "./contexts/IdentityDataContext";

import { fonts } from "../styles";
import { AccountsDataContext, AccountsDataProvider } from "./contexts/AccountsDataContext";
import { TicketsDataProvider } from "./contexts/TicketsDataContext";
import { IdentityDataContext } from "./contexts/IdentityDataContext";

import { LoggingContext } from "./contexts/LoggingContext";
import ErrorPage from "../error-page";
import { ERROR_CODES } from "./constants";

const useStyles = makeStyles({
    app: {
        boxSizing: 'border-box',
        paddingTop: tokens.spacingVerticalXL,
        paddingLeft: '10%',
        paddingRight: '10%',
        ...fonts.body,
    },
    content: {
        marginTop: tokens.spacingVerticalXL,
    },
    errorPage: {
        paddingTop: tokens.spacingVerticalXL,
    },
    version: {
        color: tokens.colorNeutralBackground2,
        position: 'fixed',
        ...shorthands.padding('15px'),
        zIndex: 1000,
        bottom: '24px',
        right: '5px',
        ':hover': {
            color: tokens.colorNeutralForeground1,
        }
    },
    session: {
        position: 'fixed',
        ...shorthands.padding('15px'),
        zIndex: 1000,
        bottom: '5px',
        right: '5px',
        color: tokens.colorNeutralBackground2,
        ':hover': {
            color: tokens.colorNeutralForeground1,
        }
    }

});

// In order to access the account context data we need to wrap the entire app in the AccountsDataProvider
const AccountInnerComponent = () => {
    const classes = useStyles();
    const { getSessionId } = useContext(LoggingContext);
    const { accountError } = useContext(AccountsDataContext);

    const version = process.env.REACT_APP_BUILD_NUMBER;
    return (
        <>
            <div id='appStyles' className={classes.app}>
                <TicketsDataProvider>
                    <NotificationsDataProvider>
                        {accountError === ERROR_CODES.ACCOUNT_NOT_FOUND && <Welcome />}
                        {accountError === ERROR_CODES.SERVICE_UNAVAILABLE && <ErrorPage sessionId={getSessionId()} errorType={ERROR_CODES.SERVICE_UNAVAILABLE} />}
                        {!accountError &&
                            <div>
                                <Header />
                                <div id='contentStyles' className={classes.content}>
                                    <Outlet />
                                </div>
                                <div className={classes.version}>Version: {version}</div>
                                <div className={classes.session}>SessionId: {getSessionId()}</div>
                            </div>
                        }
                    </NotificationsDataProvider>
                </TicketsDataProvider>
            </div>
        </>
    )
}


const InnerComponent = () => {

    const classes = useStyles();
    const { getSessionId } = useContext(LoggingContext);
    const { authError } = useContext(IdentityDataContext);

    const version = process.env.REACT_APP_BUILD_NUMBER;
    return (

        <>
            {authError && <div className={classes.errorPage}><ErrorPage sessionId={getSessionId()} errorType={"AuthError"} /></div>}
            {!authError &&
                <AccountsDataProvider>
                    <AccountInnerComponent />
                </AccountsDataProvider>
            }
        </>
    );


}

export default function Root() {
    const logFilePrefix = 'root';

    const { trackTraceVerbose } = useContext(LoggingContext);
    const classes = useStyles();

    const version = process.env.REACT_APP_BUILD_NUMBER;

    trackTraceVerbose(`${logFilePrefix}-version: ${version}`);
    trackTraceVerbose(`${logFilePrefix}-return-component`);
    return (
        <FluentProvider
            id='app'
            theme={teamsLightTheme}>
            <AuthenticationProvider>
                <IdentityDataProvider>
                    <InnerComponent />
                </ IdentityDataProvider>
            </AuthenticationProvider>
        </FluentProvider>
    );
}