import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export const date = (date: Date) => {
    return dayjs(date).format('MM/DD/YYYY');
}

export const dateTime = (date: Date) => { 
    return dayjs(date).format('MM/DD/YYYY hh:mm A');
}

export const dateTimeLong = (date: Date) => {
    return dayjs(date).format('MMMM D, h:mm A');
}

export const xDaysFromCurrentDate = (days: number) => {
    return dayjs().add(days, 'day').toDate();
}