import { createContext } from 'react';
import { useAccountsData } from '../../hooks/useAccountsData';
import { Account } from '../../types/accountTypes';

export const AccountsDataContext = createContext<{
    accounts: any[],
    accountsLoading: boolean,
    setAccounts: any,
    accountError: undefined | {},

}>({
    accounts: [] as any[],
    accountsLoading: false,
    setAccounts: () => {},
    accountError: undefined
});


export const AccountsDataProvider = ({ children }: { children: any }) => {
    const { accounts, accountsLoading, setAccounts, accountError } = useAccountsData();
    return (

        <AccountsDataContext.Provider value={{accounts, accountsLoading, setAccounts, accountError }}>
            {children}
        </AccountsDataContext.Provider>
    )
}